<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
  data: () => ({
    // 上一次点击时间
    lastTime: null,
    // 当前时间
    currentTime: null,
    // 超时时间, 如果半个小时都没有点击页面就算超时
    sys_timeout: 30 * 60 * 1000,
    // 检查的时间，每隔5分钟检查一次有没有超时
    check_time: 1 * 60 * 1000,
    // 计时器
    whole_timer: null
  }),
  created() {
    localStorage.setItem("time", new Date().getTime());
    this.isLoginOut();
  },
  mounted() {
    window.addEventListener("mousedown", this.handleonmousedown);
  },
  methods: {
    //点击存储时间
    handleonmousedown() {
      localStorage.setItem("time", new Date().getTime());
    },
    // 判断是否超时
    isTimeOut() {
      // 页面上一次的点击时间
      this.lastTime = localStorage.getItem("time");
      this.currentTime = new Date().getTime();
      // 超时了
      if (this.currentTime - this.lastTime > this.sys_timeout) {
        return true;
      } else {
        return false;
      }
    },
    isLoginOut() {
      setInterval(() => {
        console.log(this.isTimeOut());
        if (this.isTimeOut()) {
          this.$router.push("/Login");
          localStorage.removeItem("Token");
          localStorage.removeItem("router");
        }
      }, this.check_time);
    }
  }
};
</script>
<style lang="less">
.v-list-item__title {
  font-size: 12px !important;
}
.v-card__title {
  font-size: 16px !important;
}
.v-btn__content {
  font-size: 12px !important;
}
html,
body {
  height: 100vh;
  overflow: hidden;
}
/deep/ .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 0.75rem;
}
/deep/ .v-label {
  font-size: 14px !important;
}
/deep/ .v-data-table--fixed-height .v-data-table__wrapper {
  padding: 16px !important;
}
/deep/ .v-text-field {
  padding-top: 0px !important;
}
</style>
