/**
 * 路由
 */
export const Homerouter = {
    namespaced: true,
    actions: {},
    mutations: {
        Homeroutersgetdata(state) {
            if (localStorage.getItem("router")) {
                let children = JSON.parse(localStorage.getItem("router"));
                for (let i = 0; i < children.length; i++) {
                    for (let t = 0; t < children[i].children.length; t++) {
                        if (children[i].children[t].meta.public == true) {
                            children[i].children.splice(t, 1);
                        }
                    }
                }
                state.Homerouter = children
            }
        }
    },
    state: {
        Homerouter: null
    },
}

