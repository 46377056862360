
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import { getAsyncRoutes } from './Paths.js'
import nameData from "@/views/Login/Data"
//核心：解决代码冗余
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
let ROUTERS = []
const routes = [
  {
    path: '*',
    meta: {
      public: true,
    },
    redirect: {
      path: '/404'
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/error/404.vue'),
  },
  {
    path: "/",
    // 重新定位方向
    redirect: "/HomeCommunity",
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('@/views/Login/Login.vue'),
  },
  {
    path: '/Home',
    name: 'Home',
    component: () => import('@/views/Home/Home.vue'),
    meta: {
      title: "主页"
    },
    children: [
      {
        path: '/HomeCommunity',
        name: 'HomeCommunity',
        component: () => import('@/views/Community/Home.vue'),
        meta: {
          title: "控制台"
        }
      }
    ]
  }
];


ROUTERS = nameData;
//动态匹配路由
getAsyncRoutes(ROUTERS).forEach(item => {
  routes[4].children.push(item)
})

const router = new VueRouter({
  mode: 'hash',
  routes
})
router.beforeEach((to, from, next) => {
  if (!localStorage.getItem('Token') && to.name !== 'Login') {
    localStorage.removeItem("Token");
    localStorage.removeItem("router");
    localStorage.removeItem("vuex");
    localStorage.removeItem("communityId");
    localStorage.removeItem("Info");
    next({ name: 'Login' })
  } else {
    next()
  }
})

export default router
