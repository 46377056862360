export function getAsyncRoutes(routes){
  const res = [];
  const keys = ['path', 'name', 'children', 'meta'];
  routes.forEach(item=>{
    const newItem = {};
    newItem.component = resolve => require([`@/views/${item.component}`],resolve);
    for (const key in item) {
      if (keys.includes(key)) {
          newItem[key] = item[key]
      }
    }
      // 若遍历的当前路由存在子路由，需要对子路由进行递归遍历
      if (newItem.children && newItem.children.length) {
        newItem.children = getAsyncRoutes(item.children)
    }
    res.push(newItem)
  })
  return res
}