
/**
 * 小区数据
 */
export const Communitys = {
    namespaced: true,
    actions: {},
    mutations: {
        getdata(state, value) {
            state.Communitysdata = value;
            state.Communitys = value.communityName
            if (value.communityId) {
                state.Communitysdata.id = value.communityId
            }
        },
        deldata(state) {
            state.Communitysdata = null;
            state.Communitys = "未选择小区"
            localStorage.setItem("communityId", "all");
        }
    },
    state: {
        Communitysdata: null,
        Communitys: "未选择小区"
    },
}

