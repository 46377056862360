const nameData = [
  {
    "action": "mdi-domain",
    "path": "/Community",
    "name": "Community",
    "component": "Community/index.vue",
    "children": [
      {
        "path": "/Community/MyCommunity",
        "name": "MyCommunity",
        "component": "Community/MyCommunity.vue",
        "meta": { title: "我的小区" },
      },
    ],
    "meta": { title: "小区" },
  },
  {
    "action": "mdi-home-edit",
    "path": '/Property',
    "name": "Property",
    "component": 'Property/index.vue',
    "children": [
      {
        "path": '/Property/BuildingManagement',
        "name": "BuildingManagement",
        "component": 'Property/BuildingManagement/index.vue',
        "meta": { title: "房屋管理" },
      },
      {
        "meta": { title: "空置房管理" },
        "path": '/Property/VacancyRate',
        "name": 'VacancyRate',
        "component": 'Property/VacancyRate/index.vue',
      }, {
        "meta": { title: "交房预约" },
        "path": '/Property/subscribe',
        "name": 'subscribe',
        "component": 'Property/subscribe/index.vue',
      }
    ],
    "meta": { title: "房产" },
  },
  {
    "action": "mdi-cash-multiple",
    "path": '/Cost',
    "name": 'Cost',
    "component": 'Cost/index.vue',
    "children": [
      {
        "meta": { title: "商户设置" },
        "path": '/Cost/MCC',
        "name": 'MCC',
        "component": 'Cost/MCC/index.vue',
      },
      {
        "meta": { title: "账单设置" },
        "path": '/Cost/BillSet',
        "name": 'BillSet',
        "component": 'Cost/BillSet/index.vue',
      },
      {
        "meta": { title: "房屋收费" },
        "path": '/Cost/HousingFees',
        "name": 'HousingFees',
        "component": 'Cost/HousingFees/index.vue',
      },
      {
        "path": '/Cost/Drawback',
        "name": 'Drawback',
        "component": 'Cost/Drawback/index.vue',
        "meta": { title: "退款审核", public: true }
      },
      {
        "path": '/Cost/HousingFees/Pay',
        "name": 'Pay',
        "component": 'Cost/HousingFees/Pay.vue',
        "meta": { title: "缴费", public: true }
      },
      {
        "path": '/Cost/Report',
        "name": 'Report',
        "component": 'Cost/Report/index.vue',
        "meta": { title: "收费报表" }
      },
      {
        "path": '/Cost/Pledge',
        "name": 'Pledge',
        "component": 'Cost/Pledge/index.vue',
        "meta": { title: "押金管理" }
      },
      {
        "path": '/Cost/Prestore',
        "name": 'Prestore',
        "component": 'Cost/Prestore/index.vue',
        "meta": { title: "预存管理" }
      },
      {
        "path": '/Cost/Offline',
        "name": 'Offline',
        "component": 'Cost/Offline/index.vue',
        "meta": { title: "线下支付方式" }
      }
    ],
    "meta": { title: "费用" },
  },
  {
    "action": "mdi-offer",
    "path": '/Work',
    "name": 'Work',
    "component": 'Work/index.vue',
    "children": [
      {
        "meta": { title: "发布公告" },
        "path": '/Work/Announce',
        "name": 'Announce',
        "component": 'Work/Announce/index.vue',
      },
      {
        "meta": { title: "轮播图" },
        "path": '/Work/BannerImg',
        "name": 'BannerImg',
        "component": 'Work/BannerImg/index.vue',
      },
      {
        "meta": { title: "访客登记" },
        "path": '/Work/VisitorLogBook',
        "name": 'VisitorLogBook',
        "component": 'Work/VisitorLogBook/index.vue',
      },
      {
        "path": '/Work/Complaint',
        "name": 'Complaint',
        "component": 'Work/Complaint/index.vue',
        "meta": { title: "投诉管理" },
      },
      {
        "path": '/Work/Complaint/ComplaintDetails',
        "name": 'ComplaintDetails',
        "component": 'Work/Complaint/ComplaintDetails.vue',
        "meta": { title: "详情", public: true }
      },
      {
        "path": '/Work/Approval',
        "name": 'Approval',
        "component": 'Work/Approval/index.vue',
        "meta": { title: "审批" },

      }
    ],
    "meta": { title: "办公" },

  },
  {
    "path": '/Parking',
    "name": 'Parking',
    "component": 'Parking/index.vue',
    "action": "mdi-car",
    "children": [
      {
        "meta": { title: "停车场管理" },
        "path": '/Parking/ParkingManagement',
        "name": 'ParkingManagement',
        "component": 'Parking/ParkingManagement/index.vue',
      },
      {
        "path": '/Parking/Carbarn',
        "name": 'Carbarn',
        "component": 'Parking/Carbarn/index.vue',
        "meta": { title: "车位信息" },

      },
      {
        "meta": { title: "车权人车辆" },
        "path": '/Parking/BusinessCar',
        "name": 'BusinessCar',
        "component": 'Parking/BusinessCar/index.vue',
      }
    ],
    "meta": { title: "车位管理" },
  },
  {
    "path": '/Repairs',
    "name": 'Repairs',
    "component": 'Repairs/index.vue',
    "action": "mdi-alert-octagon",
    "children": [
      {
        "meta": { title: "工单设置" },
        "path": '/Repairs/WarrantysSet',
        "name": 'WarrantysSet',
        "component": 'Repairs/WarrantysSet/index.vue',
      },
      {
        "meta": { title: "工单池" },
        "path": '/Repairs/WorkersChanChi',
        "name": 'WorkersChanChi',
        "component": 'Repairs/WorkersChanChi/index.vue',
      },
      {
        "path": '/Repairs/WorkersChanChi/reportDetails',
        "name": 'reportDetails',
        "component": 'Repairs/WorkersChanChi/reportDetails.vue',
        "meta": { title: "详情", public: true }
      }
    ],
    "meta": { title: "工单管理" },
  },

  {
    "path": '/Owner',
    "name": 'Owner',
    "component": 'Owner/index.vue',
    "action": "mdi-account",
    "children": [
      {
        "path": '/Owner/OwnerInformation',
        "name": 'OwnerInformation',
        "component": 'Owner/OwnerManagement/index.vue',
        "meta": { title: "业主信息" },
      },
      {
        "path": 'OwnerInformation/information',
        "name": 'information',
        "component": 'Owner/OwnerManagement/information.vue',
        "meta": { title: "业主信息", public: true }
      },
      {
        "path": '/Owner/Coupon',
        "name": 'Coupon',
        "component": 'Owner/Coupon/index.vue',
        "meta": { title: "优惠券/积分管理" },
      },
      {
        "path": '/Owner/OwnerMember',
        "name": 'OwnerMember',
        "component": 'Owner/OwnerMember/index.vue',
        "meta": { title: "家庭成员" },
      }
    ],
    "meta": { title: "业主" },
  },

  {
    "path": '/IoT',
    "name": 'IoT',
    "component": 'IoT/index.vue',
    "action": "mdi-web",
    "children": [
      {
        "meta": { title: "车辆道闸" },
        "path": '/IoT/ioTSigno',
        "name": 'ioTSigno',
        "component": 'IoT/ioTSigno/index.vue'
      },
      {
        "title": "",
        "meta": { title: "门禁设备" },
        "path": '/IoT/ioTRKE',
        "name": 'ioTRKE',
        "component": 'IoT/ioTRKE/index.vue'
      },
      // {
      //   "meta": { title: "电表设备" },
      //   "path": '/IoT/ammeter',
      //   "name": 'ammeter',
      //   "component": 'IoT/ammeter/index.vue'
      // }
    ],
    "meta": { title: "物联网" },
  },
  {
    "action": "mdi-cellphone",
    "path": '/PropertyApp',
    "name": 'PropertyApp',
    "component": 'PropertyApp/index.vue',
    "children": [
      {
        "path": '/PropertyApp/Role',
        "name": 'PropertyAppRole',
        "component": 'PropertyApp/Role/index.vue',
        "meta": { title: "部门设置" },
      },
      {
        "path": '/PropertyApp/Staff',
        "name": 'PropertyAppStaff',
        "component": 'PropertyApp/Staff/index.vue',
        "meta": { title: "员工信息" }
      }
    ],
    "meta": { title: "物业小程序" },
  },
  {
    "action": "mdi-cellphone",
    "path": '/OwnerApp',
    "name": 'OwnerApp',
    "component": 'OwnerApp/index.vue',
    "children": [
      {
        "path": '/OwnerApp/Staff',
        "name": 'OwnerAppStaff',
        "component": 'OwnerApp/Staff/index.vue',
        "meta": { title: "人员信息" }
      },
    ],
    "meta": { title: "业主小程序" },
  },
  {
    "action": "mdi-cog",
    "path": '/SetPermission',
    "name": 'SetPermission',
    "component": 'SetUser/index.vue',
    "children": [
      {
        "path": '/SetPermission/Role',
        "name": 'Role',
        "component": 'SetUser/Role/index.vue',
        "meta": { title: "角色设置" },
      },
      {
        "path": '/SetPermission/Staff',
        "name": 'Staff',
        "component": 'SetUser/Staff/index.vue',
        "meta": { title: "员工信息" }
      },

      {
        "path": '/SetPermission/UserSet',
        "name": 'UserSet',
        "component": 'SetUser/UserSet/index.vue',
        "meta": { title: "个人中心" },
      },
      {
        "meta": { title: "系统轮播图" },
        "path": '/SetUser/BannerOwned',
        "name": 'BannerOwned',
        "component": 'SetUser/BannerOwned/index.vue',
      },
    ],
    "meta": { title: "设置" },
  },
  {
    "action": "mdi-notebook",
    "path": '/OperationLog',
    "name": 'OperationLog',
    "component": 'OperationLog/index.vue',
    "children": [
      {
        "path": '/OperationLog/OperationLog',
        "name": 'OperationLog',
        "component": 'OperationLog/OperationLog/index.vue',
        "meta": { title: "日志" },
      }
    ],
    "meta": { title: "操作日志" },
  }
]
export default nameData