import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import { defineAsyncComponent } from 'vue'

import 'element-ui/lib/theme-chalk/index.css';
import VideoPlayer from 'vue-video-player/src';
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)
Vue.use(ElementUI);

Vue.prototype.$videos = Video
// 异步组件
Vue.component('Snackbar', defineAsyncComponent(() =>
  import('@/components/Snackbar/index.vue')
))
Vue.component('Uploading', defineAsyncComponent(() =>
  import('@/components/uploading/index.vue')
))
Vue.component('UploadingMember', defineAsyncComponent(() =>
  import('@/components/uploadingMember/index.vue')
))
Vue.component('Download', defineAsyncComponent(() =>
  import('@/components/download/index.vue')
))
Vue.component('file', defineAsyncComponent(() =>
  import('@/components/fileInput/index.vue')
))
Vue.component('Tooltips', defineAsyncComponent(() =>
  import('@/components/Tooltips/index.vue')
))


Vue.filter('dateFormat', function (originVal) {
  const dt = new Date(originVal)
  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')
  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')
  //时间格式年月日、时分秒 
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})



Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
