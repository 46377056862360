import Vue from 'vue'
import Vuex from 'vuex'
import { Communitys } from "./communitys";
import { Homerouter } from "./Homerouter";

import createPersistedState from "vuex-persistedstate"
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Communitys: Communitys,
    Homerouters: Homerouter
  },
  plugins: [createPersistedState()] //默认持久化所有state
})
